import { useState, useEffect } from "react";
import { Grid, Dialog, Button } from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";
import { useApp } from "../hooks/useApp";
import FancyPaper from "../components/FancyPaper";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import { requestBatchResult } from "../services/batch/BatchService";
import { useParams, useNavigate } from "react-router-dom";
import { TableSearch } from "../components/tables/TableSearch";
import { generatePDFIndividual } from "../services/downloadPdf/DownloadPDFServide";
import { getIndexData } from "../utils/LocalStorageManager";
import ValidateClientDetailsBatch from "../components/utils/ValidateClientDetalisBatch";

const BatchListResult = () => {
  const navigate = useNavigate();
  const { setLoading, authInfo, modalData, setModalData, setErrorMsg } =
    useApp();
  const [batchResult, setBatchResult] = useState<any>(null);
  const [page, setPage] = useState(0);
  const { resultId } = useParams();
  const [localCompanyData, setLocalCompanyData] = useState<any>({});
  const [dataSelected, setDataSelected] = useState<any>({});

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  useEffect(() => {
    const getData = async () => {
      const getLocalCompanyData: any = await getIndexData("companyData");

      setLocalCompanyData({
        company: {
          id: getLocalCompanyData?.companyId,
        },
        subCompany: {
          id: getLocalCompanyData?.subcompanyId,
        },
      });
    };
    getData();
  }, []);

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0);
    };
    if (!!resultId) {
      dataInit();
    }
  }, [page, resultId]);

  const handleFetchData = async (page: number) => {
    setLoading && setLoading(true);
    try {
      let response = await requestBatchResult(page, resultId!!);
      console.log(response);
      if (response) {
        setBatchResult(response);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setBatchResult(null);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage);
    }
  };

  const downloadPdfIndividual = async (data: any) => {
    setLoading && setLoading(true);
    try {
      let dataLexisNexis = { lexisNexisResult: data };
      const response = await generatePDFIndividual(
        authInfo.username,
        data?.bestName ?? "",
        localCompanyData?.company?.id || 0,
        localCompanyData?.subCompany?.id,
        dataLexisNexis
      );

      const blob = new Blob([response.data], { type: "application/pdf" }); // Cambiado a 'application/pdf'
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${data.entityName}.pdf`;
      downloadLink.click();
    } catch (error: any) {
      console.log(error);
      setErrorMsg && setErrorMsg("Error al descargar el PDF");
    }
    setLoading && setLoading(false);
  };

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    /**/
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: null,
      });
  };

  return (
    <>
      <FancyPaper pagetitle="Consultas / Verificación por lotes">
        <Grid container spacing={8} sx={{ pb: 8, pt: 4 }}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate(-1)}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item xs={12}>
            {!!batchResult && (
              <TableSearch
                typePersonValidate={"Física"}
                listSearch={batchResult[0]?.watchlist?.matches?.wlmatch}
                page={page}
                handleOpenModal={handleOpenModal}
                setDataSelected={setDataSelected}
              />
            )}

            <Dialog
              open={modalData!!.modalOpen}
              onClose={handleCancelModal}
              fullWidth
              maxWidth="lg"
            >
              <ValidateClientDetailsBatch
                data={dataSelected}
                cancelModal={handleCancelModal}
                downloadPdfIndividual={downloadPdfIndividual}
              />
            </Dialog>

            {!!batchResult && batchResult?.content?.length > 0 && (
              <FancyTablePagination
                count={
                  batchResult?.content?.length > 0
                    ? batchResult?.content?.length
                    : 0
                }
                rowsPerPage={batchResult?.size}
                page={page}
                onPageChange={handleChangePage}
                totalElements={batchResult?.totalElements}
                totalPages={batchResult?.totalPages}
                numberOfElements={batchResult?.numberOfElements}
              />
            )}
          </Grid>
        </Grid>
      </FancyPaper>
    </>
  );
};

export default BatchListResult;
